<template>
  <data-table :data="posts">
    <el-table-column
      label="Scheduled Date"
      prop="scheduled_time"
      sortable
      width="370"
    >
      <template v-if="scope.row.scheduled_time" slot-scope="scope">
        {{ formatInTimeZone(parseISO(scope.row.scheduled_time), timezone, 'ccc MMM d, yyyy @ h:mm a z') }}
        <el-tag
          v-if="!scope.row.ad.posted_through_caas"
          type="info"
          size="small"
        >
          Created by Property
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column
      label="Floorplan/Unit"
      width="240"
    >
      <template slot-scope="scope">
        {{ scope.row.ad.floorplan_name }}
        <span v-if="scope.row.ad.floorplan_name && scope.row.ad.unit_name "> / </span>
        {{ scope.row.ad.unit_name }}
      </template>
    </el-table-column>
    <el-table-column
      label="Bed/Bath/sqft"
      width="140"
    >
      <template slot-scope="scope">
        {{ scope.row.ad.content.bedroom_count }}br /
        {{ scope.row.ad.content.bathroom_count }}ba /
        {{ scope.row.ad.content.building_sqft }}
      </template>
    </el-table-column>
    <el-table-column
      label="Title"
      prop="ad.title"
    >
      <template slot-scope="scope">
        <link-button
          v-if="scope.row.ad.view_url"
          :href="scope.row.ad.view_url"
          target="_blank"
          type="primary"
        >
          {{ scope.row.ad.title }}
        </link-button>
        <template v-else>
          {{ scope.row.ad.title }}
        </template>
      </template>
    </el-table-column>
    <el-table-column
      label="Price"
      prop="ad.content.price"
    >
      <template v-if="scope.row.ad.content.price !== null" slot-scope="scope">
        ${{ scope.row.ad.content.price }}
      </template>
    </el-table-column>
    <el-table-column
      label="Region"
      prop="ad.major_region"
    >
      <template v-if="(scope.row.ad.major_region||scope.row.ad.sub_region||scope.row.ad.sub_sub_region) !== null" slot-scope="scope">
        <el-row type="flex" class="region-container">
          <div class="region">
            <span class="label">{{ buildRegionString(scope.row) }}</span>
            <div v-if="buildRegionString(scope.row) !== craigslistRegion && craigslistRegion !== null" class="region-icon region-icon--warning">
              <warning-icon />
            </div>
          </div>
        </el-row>
      </template>
    </el-table-column>
    <el-table-column
      label="Account"
      prop="ad.account"
    >
      <template slot-scope="scope">
        {{ scope.row.ad.account }}
      </template>
    </el-table-column>
    <el-table-column
      label="Ad Status"
      prop="status"
      width="120"
      class-name="capitalize"
      sortable
    >
      <template slot-scope="scope">
        <el-tooltip
          :content="scope.row.failure_reason_humanized"
          :disabled="!scope.row.failure_reason_humanized"
        >
          <el-tag
            :type="getStatusTagType(calculateAdStatus(scope.row))"
            size="medium"
          >
            {{ calculateAdStatus(scope.row) }}
          </el-tag>
        </el-tooltip>
      </template>
    </el-table-column>
  </data-table>
</template>

<script>
import { parseISO } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import CraigslistAPI from '@/services/api/craigslist'
import DataTable from '@/components/tables/DataTable'
import LinkButton from '@/components/buttons/LinkButton'
import WarningIcon from '@/assets/icons/svg/exclamation_triangle.svg'

export default {
  name: 'PostingHistoryTable',
  components: {
    DataTable,
    LinkButton,
    WarningIcon
  },
  props: {
    posts: {
      type: Array,
      required: true
    },
    timezone: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      craigslistProperty: '',
      craigslistRegion: ''
    }
  },
  created () {
    this.formatInTimeZone = formatInTimeZone
    this.parseISO = parseISO
    this.fetchData()
  },
  methods: {

    /**
     * Fetch property region info from the API and build the region info string
     */
    async fetchData () {
      try {
        this.craigslistProperty = await CraigslistAPI.properties.retrieve(this.$route.params.id)
        this.craigslistRegion = this.craigslistProperty.major_region
        if (this.craigslistProperty.sub_region) {
          this.craigslistRegion = `${this.craigslistRegion} > ${this.craigslistProperty.sub_region}`
        }
        if (this.craigslistProperty.sub_sub_region) {
          this.craigslistRegion = `${this.craigslistRegion} > ${this.craigslistProperty.sub_sub_region}`
        }
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * This function grabs specific region info using the .find() method
     * @param {string} regions - The key of the region you want (major_regions, sub_regions, sub_sub_regions).
     * @param {string} rValue - The value within the region object you would like to match (id, abbreviation, etc).
     * @param match - The value you are using to find and match the rValue with (ex. row.ad.sub_sub_region).
     * @param {string} field - The field name you would like to grab from the found region object (id, name, etc).
     */
    getRegionInfo (regions, rValue, match, field) {
      return this.$store.state.regions[regions].find(r => r[rValue] === match)[field]
    },

    /**
     * Builds the ad region info string
     * @param {object} row
     */
    buildRegionString (row) {
      if (row.ad.sub_sub_region) {
        const adSubRegionId = this.getRegionInfo('sub_sub_regions', 'id', row.ad.sub_sub_region, 'sub_region_id')
        const adMajorRegionId = this.getRegionInfo('sub_regions', 'id', adSubRegionId, 'major_region_id')
        const regionString = [this.getRegionInfo('major_regions', 'id', adMajorRegionId, 'name'), this.getRegionInfo('sub_regions', 'id', adSubRegionId, 'name'), this.getRegionInfo('sub_sub_regions', 'id', row.ad.sub_sub_region, 'name')].join(' > ')
        return regionString
      }
      if (row.ad.sub_region) {
        const adMajorRegionId = this.getRegionInfo('sub_regions', 'id', row.ad.sub_region, 'major_region_id')
        const regionString = [this.getRegionInfo('major_regions', 'id', adMajorRegionId, 'name'), this.getRegionInfo('sub_regions', 'id', row.ad.sub_region, 'name')].join(' > ')
        return regionString
      }
      if (row.ad.major_region) {
        return this.getRegionInfo('major_regions', 'abbreviation', row.ad.major_region, 'name')
      }
    },

    calculateAdStatus (row) {
      const adStates = ['deleted', 'expired', 'flagged'].includes(row.ad.state)
      if (adStates) {
        return row.ad.state
      }
      if (row.status === 'failed' && row.failure_reason === 'expired') {
        return 'app offline'
      }
      return row.status
    },
    /**
     * Returns the ElementUI tag type given the ad status
     *
     * @param {String} status
     * @returns {String}
     */
    getStatusTagType (status) {
      switch (status) {
        case 'scheduled':
        case 'posting':
          return 'warning'
        case 'pending':
          return ''
        case 'successful':
          return 'success'
        case 'failed':
          return 'danger'
        default: // unscheduled, deleted, expired, flagged, app offline
          return 'info'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$warning: #e6a23c;
.region-container {
  .region {
    margin-left: 0;
    display: flex;
    align-items: center;

    .region-icon {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 40px;
        height: 40px;
      }

      &.region-icon--warning {
        color: $warning;
        svg {
          fill: $warning;
        }
      }
    }
    .label {
      margin-right: 0.5em;
    }
  }
}
</style>
